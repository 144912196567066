import { memo, useContext } from 'react';
import { ResponsivePie } from '@nivo/pie';
import type { Chat } from '@/brand-insights/types';
import { theme } from '@/components/SurveyResponses/Chart.Common';
import { ChartHoverText } from './Message.Response.Chart.Hover';
import { LegendCollapseToggleContext } from './context';

type Props = {
    chart: Chat.PieChart;
    colors: string[];
};

export const NivoPieChart = memo((props: Props) => {
  const returnedData = props.chart.data;

  const data = [];

  for (let i = 0; i < returnedData.length; i++) {
    data.push({ ...returnedData[i], color: props.colors[i] });
  }

  const [open, setOpen] = useContext(LegendCollapseToggleContext);

  return (
    <div style= {{ height: 320, width: '100%', position: 'relative' }}>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={props.colors}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              0.2,
            ],
          ],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLinkLabelsStraightLength={24}
        arcLabelsTextColor="#FFFFFF"
        tooltip={e => {
          const { datum } = e;
          return <ChartHoverText name={datum.label} count={datum.value} />;
        }}
        onClick={data => {
          setOpen(true);
        }}
        theme={theme} />
    </div>

  );
});