import { memo, useContext } from 'react';
import { ResponsiveLine } from '@nivo/line';
import type { Chat } from '@/brand-insights/types';
import { theme } from '@/components/SurveyResponses/Chart.Common';
import { ChartHoverText } from './Message.Response.Chart.Hover';
import { LegendCollapseToggleContext } from './context';

type Props = {
    chart: Chat.LineChart;
    colors: string[];
};

export const NivoLineChart = memo((props: Props) => {
  const randomColor = props.colors[Math.floor(Math.random() * props.colors.length)];
  const data = [{ 'id': props.chart.id, 'data': props.chart.data, 'color': randomColor }];

  const [open, setOpen] = useContext(LegendCollapseToggleContext);
  return (
    <div style= {{ height: 320, width: '100%', position: 'relative' }}>
      <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false,
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    colors={randomColor}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: props.chart.xAxisTitle,
      legendOffset: 36,
      legendPosition: 'middle',
      truncateTickAt: 0,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: props.chart.yAxisTitle,
      legendOffset: -40,
      legendPosition: 'middle',
      truncateTickAt: 0,
    }}
    pointSize={8}
    pointColor={{ from: 'color' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel="data.yFormatted"
    pointLabelYOffset={-12}
    enableCrosshair={true}
    useMesh={true}
    tooltip={e => {

      return <ChartHoverText name={e.point.data.xFormatted} count={e.point.data.yFormatted} />;
    }}
    onClick={data => {
      setOpen(true);
    }}
    theme={theme} />
    </div>
  );
});

