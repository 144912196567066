import { useEffect, useMemo } from 'react';
import { useParams, useLocation, useHistory, generatePath } from 'react-router-dom';
import type { AxiosError } from 'axios';
import { Helmet } from 'react-helmet';
import addDays from 'date-fns/addDays';
import { useQuery } from '@tanstack/react-query';
import * as $api from '@api';
import * as consts from '@consts';
import { useCookie } from '@utils';
import { ActivityIndicator } from '@/components/ActivityIndicator';
import { ProjectSurvey } from './Project.Survey';
import { NotValidError, LoadingError } from './Error';

type Params = {
  projectId: string;
  configIdentifier: string;
  userId?: string;
};

export const ProjectEntry = () => {
  return (
    <>
      <Helmet
        defaultTitle="Survey"
        titleTemplate="%s | Survey" />
      <ProjectEntryComponent />
    </>
  );
};

function useQueryStringMap() {
  const { search } = useLocation();
  // note: does not handle arrays
  return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
}

type UseTransformedParamsData = {
  pageQueries: Record<string, string>;
};

function useTransformedParams(data: UseTransformedParamsData) {
  const params = useParams<Params>();
  const [cookie, setCookie] = useCookie(`ext-src-last-uid-v2:${params.configIdentifier}`);

  const userId = params.userId;

  // if previous good uid exists and secret querystring is being used then prefer already generated uid
  // if (cookie?.length && data.pageQueries['_ss']?.length) {
  //   userId = cookie;
  // }

  useEffect(() => {
    if (userId?.length) {
      setCookie({
        value: userId,
        expires: addDays(new Date(), 14),
      });
    }
  }, [
    setCookie,
    userId,
  ]);

  return useMemo(() => ({
    projectId: params.projectId,
    configIdentifier: params.configIdentifier,
    userId,
  }), [
    params.projectId,
    params.configIdentifier,
    userId,
  ]);
}

const ProjectEntryComponent = () => {
  const pageQueries = useQueryStringMap();
  const params = useTransformedParams({ pageQueries });
  const history = useHistory();

  const query = useQuery({
    queryKey: ['external-sourcing-get-project-entry', params.projectId, params.configIdentifier, params.userId, pageQueries], queryFn: () => {
      return $api.projects.externalSourcing.getProjectEntry({
        projectId: +params.projectId,
        configIdentifier: params.configIdentifier,
        userId: params.userId,
        pageQueries,
      })
        .then(data => data.data);
    }, refetchOnWindowFocus: false, retry: 1,
  });

  useEffect(() => {
    if (query.data?.success && pageQueries['_ss']?.length) {
      // note: don't include _ss queryparam for redirect (reduce leaking from sharing browser url)
      const { _ss, ...rest } = pageQueries;
      const search = new URLSearchParams(rest);
      history.replace(generatePath(`${consts.path.ExternalSourcingProject.Project}?${search.toString()}`, {
        projectId: params.projectId,
        configIdentifier: params.configIdentifier,
        userId: query.data.link.uid,
      }));
    }
  }, [
    history,
    params.projectId,
    params.configIdentifier,
    params.userId,
    query.data,
    pageQueries,
  ]);

  useEffect(() => {
    if (query.data?.success === false && query.data.error === 'survey-completed') {
      window.location.href = query.data.redirects.completionUrl;
    }
    else if (query.data?.success === false && query.data.error === 'invalid-signature') {
      window.location.href = query.data.invalidSignatureUrl;
    }
  }, [query.data]);

  if (query.isError) {
    const error = query.error['response'] as AxiosError['response'];
    if (error.status >= 400 && error.status < 500) {
      return <NotValidError />;
    }

    return <LoadingError />;
  }

  if (query.isLoading || !query.data) {
    return <ActivityIndicator show />;
  }

  if (query.data.success === false) {
    if (query.data.error === 'survey-completed') {
      return <ActivityIndicator show />;
    }

    return <LoadingError />;
  }

  return (
    <ProjectSurvey
      projectId={+params.projectId}
      userId={params.userId}
      completionUrl={query.data?.redirects?.completionUrl}
      screenOutUrl={query.data?.redirects?.screenOutUrl}
      overquotaUrl={query.data?.redirects?.overquotaUrl} />
  );
};